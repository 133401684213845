<template>
  <div>
    <b-row v-if="isFailValidation" class="justify-content-center">
      <b-col xl="9" lg="9" md="9" sm="12">
        <div class="is-invalid-feedback-message">
          {{ textValidation }}
        </div>
      </b-col>
    </b-row>
    <b-row
      class="justify-content-center core__input__group__wrapper payment-method"
    >
      <b-col xl="3" lg="3" md="3" sm="12" class="d-flex">
        <span class="font-weight-bold">{{
          $t("payment-agency.corresponding_payment_method")
        }}</span>
      </b-col>
      <b-col xl="6" lg="6" md="6" sm="12">
        <b-row class="d-flex">
          <b-col xl="4" lg="4" md="4" sm="12">
            <span class="font-weight-bold">
              {{ $t("payment-agency.view_policy") }}
            </span>
          </b-col>
          <b-col xl="8" lg="8" md="8" sm="12">
            <span class="font-weight-bold">
              {{ $t("payment-agency.api_end_point") }}
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <div v-for="(item, idx) in paymentMethodList" :key="idx">
      <b-row
        class="d-flex justify-content-center core__input__group__wrapper pb-md-2 pb-lg-0 payment-method"
      >
        <b-col xl="3" lg="3" md="3" sm="12">
          <div class="payment-method__create-edit common__label__input">
            <b-form-checkbox
              :id="item.payment_method"
              v-model="paymentMethodList[idx]['method_flag']"
              :state="
                errorValidation &&
                validationResult &&
                validationResult.hasOwnProperty(
                  keyName + '.' + idx + '.payment_method'
                )
                  ? false
                  : null
              "
              :name="item.payment_method"
              :value="1"
              :unchecked-value="0"
              @change="resetApiEndPoint(idx)"
            >
              {{ paymentMethod[item.payment_method.toLowerCase()]["text"] }}
            </b-form-checkbox>
          </div>
          <div
            v-show="
              errorValidation &&
              validationResult &&
              validationResult.hasOwnProperty(
                keyName + '.' + idx + '.payment_method'
              )
            "
            class="is-invalid-feedback-message"
          >
            {{ validationResult[keyName + "." + idx + ".payment_method"] }}
          </div>
        </b-col>
        <b-col xl="6" lg="6" md="6" sm="12">
          <b-row class="d-flex">
            <b-col xl="4" lg="4" md="4" sm="12">
              <b-form-checkbox
                v-model="paymentMethodList[idx]['view_policy_flag']"
                :value="1"
                :unchecked-value="0"
                :disabled="!paymentMethodList[idx]['method_flag']"
                class="mt-2"
              ></b-form-checkbox>
            </b-col>
            <b-col xl="8" lg="8" md="8" sm="12">
              <div
                :class="{
                  'ml-40': hasAutoPaymentApiEndPoint(paymentMethodList[idx]),
                }"
              >
                <div class="d-flex">
                  <span
                    v-if="hasAutoPaymentApiEndPoint(paymentMethodList[idx])"
                    class="text-nowrap pt-2 mr-2"
                  >
                    {{ $t("payment-agency.each_time") }}
                  </span>
                  <b-form-input
                    v-model="paymentMethodList[idx]['api_end_point']"
                    :disabled="!paymentMethodList[idx]['method_flag']"
                    :class="{
                      'is-invalid':
                        errorValidation &&
                        validationResult &&
                        validationResult.hasOwnProperty(
                          keyName + '.' + idx + '.api_end_point'
                        ),
                    }"
                  />
                </div>
                <div
                  v-show="
                    errorValidation &&
                    validationResult &&
                    validationResult.hasOwnProperty(
                      keyName + '.' + idx + '.api_end_point'
                    )
                  "
                  class="is-invalid-feedback-message"
                  :class="{
                    'margin-left-40': hasAutoPaymentApiEndPoint(
                      paymentMethodList[idx]
                    ),
                  }"
                >
                  {{ validationResult[keyName + "." + idx + ".api_end_point"] }}
                </div>
                <div class="d-flex">
                  <span
                    v-if="hasAutoPaymentApiEndPoint(paymentMethodList[idx])"
                    class="text-nowrap pt-3 mr-2"
                  >
                    {{ $t("payment-agency.continuation") }}
                  </span>
                  <b-form-input
                    v-if="hasAutoPaymentApiEndPoint(paymentMethodList[idx])"
                    v-model="
                      paymentMethodList[idx]['auto_payment_api_end_point']
                    "
                    :disabled="!paymentMethodList[idx]['method_flag']"
                    class="mt-11"
                    :class="{
                      'is-invalid':
                        errorValidation &&
                        validationResult &&
                        validationResult.hasOwnProperty(
                          keyName + '.' + idx + '.auto_payment_api_end_point'
                        ),
                    }"
                  />
                </div>
                <div
                  v-if="hasAutoPaymentApiEndPoint(paymentMethodList[idx])"
                  v-show="
                    errorValidation &&
                    validationResult &&
                    validationResult.hasOwnProperty(
                      keyName + '.' + idx + '.auto_payment_api_end_point'
                    )
                  "
                  class="is-invalid-feedback-message"
                  :class="{
                    'margin-left-40': hasAutoPaymentApiEndPoint(
                      paymentMethodList[idx]
                    ),
                  }"
                >
                  {{
                    validationResult[
                      keyName + "." + idx + ".auto_payment_api_end_point"
                    ]
                  }}
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import handleValidateMixin from "@/mixins/handle-validate-mixin";
import { paymentMethod } from "@/constants/commons";
import _ from "lodash";

export default {
  name: "PaymentMethodCheckbox",
  mixins: [handleValidateMixin],
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      paymentMethod: paymentMethod,
      paymentMethodList: [
        {
          payment_method: paymentMethod.bank_transfer.value,
          method_flag: 0,
          view_policy_flag: 0,
          api_end_point: "",
        },
        {
          payment_method: paymentMethod.account_transfer.value,
          method_flag: 0,
          view_policy_flag: 0,
          api_end_point: "",
        },
        {
          payment_method: paymentMethod.combini.value,
          method_flag: 0,
          view_policy_flag: 0,
          api_end_point: "",
        },
        {
          payment_method: paymentMethod.credit.value,
          method_flag: 0,
          view_policy_flag: 0,
          api_end_point: "",
          auto_payment_api_end_point: "",
        },
        {
          payment_method: paymentMethod.payeasy.value,
          method_flag: 0,
          view_policy_flag: 0,
          api_end_point: "",
        },
        {
          payment_method: paymentMethod.paypal.value,
          method_flag: 0,
          view_policy_flag: 0,
          api_end_point: "",
        },
      ],
    };
  },
  computed: {
    hasAutoPaymentApiEndPoint() {
      return (item) => !_.isUndefined(item.auto_payment_api_end_point);
    },
  },
  watch: {
    paymentMethodList: {
      deep: true,
      handler: function () {
        this.$emit("input", this.paymentMethodList);
      },
    },
  },
  created() {
    // get data when edit
    if (!_.isEmpty(this.value)) {
      let paymentMethodSelected = this.value.reduce(
        (arr, v) => ({ ...arr, [v.payment_method]: v }),
        {}
      );
      this.paymentMethodList = this.paymentMethodList.map((item) => {
        if (this.hasAutoPaymentApiEndPoint(item)) {
          item.auto_payment_api_end_point =
            paymentMethodSelected[item.payment_method][
              "auto_payment_api_end_point"
            ];
        }
        return Object.keys(paymentMethodSelected).includes(item.payment_method)
          ? {
              ...item,
              method_flag:
                paymentMethodSelected[item.payment_method]["method_flag"],
              api_end_point:
                paymentMethodSelected[item.payment_method]["api_end_point"],
              view_policy_flag:
                paymentMethodSelected[item.payment_method]["view_policy_flag"],
            }
          : { ...item };
      });
    }
  },
  methods: {
    resetApiEndPoint(idx) {
      if (!this.paymentMethodList[idx]["method_flag"]) {
        this.paymentMethodList[idx]["api_end_point"] = "";
      }
    },
  },
};
</script>

<style scoped lang="scss">
.payment-method {
  margin-bottom: 5px;
  &__create-edit {
    .custom-control-label {
      font-size: 12px;
      text-transform: uppercase;
      display: inline-block;
      min-height: 1.5rem;
      display: flex;
      align-items: center;
      font-weight: bold;
    }
  }
}
.mt-11 {
  margin-top: 11px;
}
.ml-40 {
  margin-left: -40px;
}
.margin-left-40 {
  margin-left: 40px;
}
@media (max-width: 767.9px) {
  .ml-40 {
    margin-left: 0;
  }
}
</style>
