<template>
  <div class="border-top">
    <b-alert class="text-center" show variant="danger">
      <i18n path="profile.label_profile_renew_expired_date">
        <b
          slot="link"
          class="text-info font-weight-bold cursor-pointer"
          styles="text-decoration-style: solid;"
          @click="openModalCreateInvoice"
        >
          <i18n path="profile.label_profile_renew_expired_date_link" />
        </b>
      </i18n>
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "ClientAlertExpiredDate",
  methods: {
    openModalCreateInvoice() {
      this.$modal.show("member_type_renewable_flag_dialog");
    },
  },
};
</script>

<style lang="scss" scoped></style>
