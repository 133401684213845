var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isFailValidation)?_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"xl":"9","lg":"9","md":"9","sm":"12"}},[_c('div',{staticClass:"is-invalid-feedback-message"},[_vm._v(" "+_vm._s(_vm.textValidation)+" ")])])],1):_vm._e(),_c('b-row',{staticClass:"justify-content-center core__input__group__wrapper payment-method"},[_c('b-col',{staticClass:"d-flex",attrs:{"xl":"3","lg":"3","md":"3","sm":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t("payment-agency.corresponding_payment_method")))])]),_c('b-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"12"}},[_c('b-row',{staticClass:"d-flex"},[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("payment-agency.view_policy"))+" ")])]),_c('b-col',{attrs:{"xl":"8","lg":"8","md":"8","sm":"12"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(_vm.$t("payment-agency.api_end_point"))+" ")])])],1)],1)],1),_vm._l((_vm.paymentMethodList),function(item,idx){return _c('div',{key:idx},[_c('b-row',{staticClass:"d-flex justify-content-center core__input__group__wrapper pb-md-2 pb-lg-0 payment-method"},[_c('b-col',{attrs:{"xl":"3","lg":"3","md":"3","sm":"12"}},[_c('div',{staticClass:"payment-method__create-edit common__label__input"},[_c('b-form-checkbox',{attrs:{"id":item.payment_method,"state":_vm.errorValidation &&
              _vm.validationResult &&
              _vm.validationResult.hasOwnProperty(
                _vm.keyName + '.' + idx + '.payment_method'
              )
                ? false
                : null,"name":item.payment_method,"value":1,"unchecked-value":0},on:{"change":function($event){return _vm.resetApiEndPoint(idx)}},model:{value:(_vm.paymentMethodList[idx]['method_flag']),callback:function ($$v) {_vm.$set(_vm.paymentMethodList[idx], 'method_flag', $$v)},expression:"paymentMethodList[idx]['method_flag']"}},[_vm._v(" "+_vm._s(_vm.paymentMethod[item.payment_method.toLowerCase()]["text"])+" ")])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
            _vm.errorValidation &&
            _vm.validationResult &&
            _vm.validationResult.hasOwnProperty(
              _vm.keyName + '.' + idx + '.payment_method'
            )
          ),expression:"\n            errorValidation &&\n            validationResult &&\n            validationResult.hasOwnProperty(\n              keyName + '.' + idx + '.payment_method'\n            )\n          "}],staticClass:"is-invalid-feedback-message"},[_vm._v(" "+_vm._s(_vm.validationResult[_vm.keyName + "." + idx + ".payment_method"])+" ")])]),_c('b-col',{attrs:{"xl":"6","lg":"6","md":"6","sm":"12"}},[_c('b-row',{staticClass:"d-flex"},[_c('b-col',{attrs:{"xl":"4","lg":"4","md":"4","sm":"12"}},[_c('b-form-checkbox',{staticClass:"mt-2",attrs:{"value":1,"unchecked-value":0,"disabled":!_vm.paymentMethodList[idx]['method_flag']},model:{value:(_vm.paymentMethodList[idx]['view_policy_flag']),callback:function ($$v) {_vm.$set(_vm.paymentMethodList[idx], 'view_policy_flag', $$v)},expression:"paymentMethodList[idx]['view_policy_flag']"}})],1),_c('b-col',{attrs:{"xl":"8","lg":"8","md":"8","sm":"12"}},[_c('div',{class:{
                'ml-40': _vm.hasAutoPaymentApiEndPoint(_vm.paymentMethodList[idx]),
              }},[_c('div',{staticClass:"d-flex"},[(_vm.hasAutoPaymentApiEndPoint(_vm.paymentMethodList[idx]))?_c('span',{staticClass:"text-nowrap pt-2 mr-2"},[_vm._v(" "+_vm._s(_vm.$t("payment-agency.each_time"))+" ")]):_vm._e(),_c('b-form-input',{class:{
                    'is-invalid':
                      _vm.errorValidation &&
                      _vm.validationResult &&
                      _vm.validationResult.hasOwnProperty(
                        _vm.keyName + '.' + idx + '.api_end_point'
                      ),
                  },attrs:{"disabled":!_vm.paymentMethodList[idx]['method_flag']},model:{value:(_vm.paymentMethodList[idx]['api_end_point']),callback:function ($$v) {_vm.$set(_vm.paymentMethodList[idx], 'api_end_point', $$v)},expression:"paymentMethodList[idx]['api_end_point']"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.errorValidation &&
                  _vm.validationResult &&
                  _vm.validationResult.hasOwnProperty(
                    _vm.keyName + '.' + idx + '.api_end_point'
                  )
                ),expression:"\n                  errorValidation &&\n                  validationResult &&\n                  validationResult.hasOwnProperty(\n                    keyName + '.' + idx + '.api_end_point'\n                  )\n                "}],staticClass:"is-invalid-feedback-message",class:{
                  'margin-left-40': _vm.hasAutoPaymentApiEndPoint(
                    _vm.paymentMethodList[idx]
                  ),
                }},[_vm._v(" "+_vm._s(_vm.validationResult[_vm.keyName + "." + idx + ".api_end_point"])+" ")]),_c('div',{staticClass:"d-flex"},[(_vm.hasAutoPaymentApiEndPoint(_vm.paymentMethodList[idx]))?_c('span',{staticClass:"text-nowrap pt-3 mr-2"},[_vm._v(" "+_vm._s(_vm.$t("payment-agency.continuation"))+" ")]):_vm._e(),(_vm.hasAutoPaymentApiEndPoint(_vm.paymentMethodList[idx]))?_c('b-form-input',{staticClass:"mt-11",class:{
                    'is-invalid':
                      _vm.errorValidation &&
                      _vm.validationResult &&
                      _vm.validationResult.hasOwnProperty(
                        _vm.keyName + '.' + idx + '.auto_payment_api_end_point'
                      ),
                  },attrs:{"disabled":!_vm.paymentMethodList[idx]['method_flag']},model:{value:(
                    _vm.paymentMethodList[idx]['auto_payment_api_end_point']
                  ),callback:function ($$v) {_vm.$set(_vm.paymentMethodList[idx], 'auto_payment_api_end_point', $$v)},expression:"\n                    paymentMethodList[idx]['auto_payment_api_end_point']\n                  "}}):_vm._e()],1),(_vm.hasAutoPaymentApiEndPoint(_vm.paymentMethodList[idx]))?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                  _vm.errorValidation &&
                  _vm.validationResult &&
                  _vm.validationResult.hasOwnProperty(
                    _vm.keyName + '.' + idx + '.auto_payment_api_end_point'
                  )
                ),expression:"\n                  errorValidation &&\n                  validationResult &&\n                  validationResult.hasOwnProperty(\n                    keyName + '.' + idx + '.auto_payment_api_end_point'\n                  )\n                "}],staticClass:"is-invalid-feedback-message",class:{
                  'margin-left-40': _vm.hasAutoPaymentApiEndPoint(
                    _vm.paymentMethodList[idx]
                  ),
                }},[_vm._v(" "+_vm._s(_vm.validationResult[ _vm.keyName + "." + idx + ".auto_payment_api_end_point" ])+" ")]):_vm._e()])])],1)],1)],1)],1)})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }