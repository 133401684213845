<template>
  <base-modal-dialog
    :name="name"
    :height="config.height ?? 'auto'"
    :width="config.width"
    :draggable="draggable"
    resizable
    adaptive
  >
    <b-card
      :header-html="headerDialog"
      :header-bg-variant="color"
      header-text-variant="white"
      class="text-white m-0"
      no-body
    >
      <b-card-body>
        <div class="d-block">
          <slot name="content">
            <p
              v-if="!useVHtml"
              class="confirmText text-left text-dark p-1"
              :class="{ 'fixed-height': fixedHeight }"
            >
              {{ confirmText }}
            </p>
            <p
              v-else
              class="confirmText text-left text-dark p-1"
              :class="{ 'fixed-height': fixedHeight }"
            >
              <span v-html="confirmText"></span>
            </p>
          </slot>
        </div>

        <div class="d-block mt-2">
          <slot name="btn-action">
            <div class="d-flex w-100 justify-content-end g-5">
              <b-button :variant="color" @click="confirmOK">
                {{ textButtonConfirm }}
              </b-button>
              <b-button @click="cancel">
                {{ cancelButton }}
              </b-button>
            </div>
          </slot>
        </div>
      </b-card-body>
    </b-card>
  </base-modal-dialog>
  <!-- <b-modal
        :id="idComponent"
        centered
        :header-bg-variant="color"
        :title="$t('panel.label_confirm_title')"
        v-on="$listeners"
        lazy
        >
        <template #default>
            <slot name="content">
                <p class="confirmText text-left">{{ confirmText }}</p>
            </slot> 
        </template>
        <template #modal-footer="{ ok,cancel }">
            <div class="d-flex w-100 justify-content-end" style="gap: 5px;">
                <b-button :variant="color" @click="ok()">
                    {{  textButtonConfirm }}
                </b-button>
                <b-button @click="cancel">
                    {{ $t("panel.label_cancel_btn_modal") }}
                </b-button>
            </div>
        </template>
    </b-modal> -->
</template>

<script>
import _ from "lodash";

export default {
  name: "BaseModalConfirm",
  props: {
    name: {
      type: String,
      default: "base-modal-confirm",
    },
    remove: {
      type: Boolean,
      default: false,
    },
    idComponent: {
      type: String,
      default: "modalConfirm",
    },
    customConfirmText: {
      type: String,
      default: "",
    },
    useVHtml: {
      type: Boolean,
      default: false,
    },
    fixedHeight: {
      type: Boolean,
      default: false,
    },
    draggable: {
      type: Boolean,
      default: true,
    },
    header: String,
    config: {
      type: Object,
      default: function () {
        return {
          width: 380,
          height: "auto",
        };
      },
    },
  },
  computed: {
    color() {
      return this.remove ? "danger" : "primary";
    },
    confirmText() {
      return !_.isEmpty(this.customConfirmText)
        ? this.customConfirmText
        : this.remove
        ? this.$t("panel.label_confirm_text_remove")
        : this.$t("panel.label_confirm_text_save");
    },
    textButtonConfirm() {
      // return this.remove ? this.$t('panel.label_delete_btn_modal') : 'OK';
      return "OK";
    },
    cancelButton() {
      return this.$t("panel.label_cancel_btn_modal");
    },
    headerDialog() {
      return this.header || this.$t("panel.label_confirm_title");
    },
  },
  methods: {
    confirmOK() {
      this.$modal.hide(this.name);
      this.$emit("confirm-process");
    },
    closeModal() {
      this.$emit("update:show", false);
    },
    cancel() {
      this.$modal.hide(this.name);
    },
  },
};
</script>

<style scoped>
.card {
  border: none;
}
.confirmText {
  white-space: pre-line;
  width: 100%;
  text-align: center;
}
.fixed-height {
  display: inline-block !important;
  height: 60vh;
  overflow: auto;
}
</style>
